import React from 'react'
import theme from '../helpers/theme'
import Layout from '../components/layout'
import FeatureThumbnail from '../components/feature-thumbnail'
import SEO from '../components/seo'

const FeaturesIndexTemplate = (props) => {

  const settings = props.data.settings.edges[0].node



  const {
    readFeature
  } = settings

  return (
    <Layout image={settings.navImageFeatures} theme={theme.red} settings={settings} location={props.location}>
      <SEO title={'Features'} />

      {/* <section className="masonry gutters inner">
        {props.data.features &&
          props.data.features.edges.map(({node}, i) => (
            <div className="column-cell gutters inner" key={`${node.slug}-feature-link`}>
              <FeatureThumbnail {...node} theme={theme.green}  />
            </div>
          ))}
      </section> */}

      <div className="masonry border-top">
        {props.data.features &&
          props.data.features.edges.map(({node}, i) => (
            <FeatureThumbnail className="feature-thumbnail column-cell" {...node} theme={theme.green} linkLabel={readFeature} key={`${node.slug}-features-link`} />
          ))}
      </div>

    </Layout>
  )
}

export default FeaturesIndexTemplate
