import React from 'react'
import { Link } from 'gatsby'
import Markdown from '../components/markdown'
import toRgb from '../helpers/rgb'
import IMG from '../components/image'

const FeatureThumbnail = (props) => {
  const rgb = toRgb(props.theme.highlight.background)
  // console.log(props)
  return (
    <figure className={`${props.className} border-bottom`}>
      <Link to={`${props.node_locale === 'en-US' ? '' : '/fr'}/feature/${props.slug}`} className="flex h100">
        <figcaption className="padx2 border-right flex-between h100">
          {/* {props.thumbnailCopy ? (
            <Markdown field={props.thumbnailCopy} className="mbx2" />
          ) : (
            <h2 className="mbx2">{props.title}</h2>
          )} */}
          <h2 className="mbx2">{props.title}</h2>
          <div className="h5 style-as-link link">{props.linkLabel}</div>
        </figcaption>
        <div className="poster prel flex-center">
          <div className="flex-center fs">
            <IMG {...props.coverImage} className="original full" />
            {/*<IMG {...props.coverImage} className="blend" />*/}
          </div>


        </div>
      </Link>
    </figure>
  )
}

export default FeatureThumbnail
